<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold my-1 mr-5">
          {{ this.$t("admin.form.create_user") }}
        </h5>
        <!--end::Page Title-->
        <b-button
          @click="$router.push({ name: 'UsersList' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
    </div>
    <basic-form
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { getConstants, getTimezones } from "@/api/misc.api";

import {
  createStakeholder,
  getDepartments,
  getCompanies
} from "@/api/admin/stakeholders.api";
import BasicForm from "@/components/form/BasicForm.component";
import { validRFC5322, validText, validPhone } from "@/utils/validators";
import { getEditedProfiles, parseProfile, getError } from "@/utils/helpers";

export default {
  name: "UserCreate",
  components: {
    BasicForm
  },
  data() {
    return {
      loaded: false,
      errors: [],
      error: false,
      form: {},
      departments: [],
      profiles: [],
      companiesProfiles: [],
      isSubmitting: false,
      tableFields: [
        {
          key: "name",
          sortable: true,
          stickyColumn: true,
          isRowHeader: true,
          thClass: "col-4"
        },
        {
          key: "controller",
          label: this.$t(`admin.form.profile_options.operator`),
          thClass: "col-4",
          sortable: true
        },
        {
          key: "admin",
          label: this.$t(`admin.form.profile_options.admin`),
          thClass: "col-4",
          sortable: true
        }
      ]
    };
  },
  methods: {
    ...mapActions("Admin", [
      "loadCompanySelected",
      "loadUsers",
      "loadDepartments",
      "reset"
    ]),
    callGetError(code, params) {
      return getError(code, params);
    },
    save(data) {
      this.isSubmitting = true;
      let result = {};
      let profiles = getEditedProfiles(
        this.form.profile,
        this.companySelected.stakeholderId
      );
      _.forOwn(profiles, (value, key) => {
        if (profiles[key].length > 0) {
          result = { ...result, [key]: value };
        }
      });
      if (data.assignated_profiles["ADMIN"].length > 0) {
        result["ADMIN"] = _.concat(
          _.toArray(result["ADMIN"]),
          _.toArray(data.assignated_profiles["ADMIN"])
        );
      }
      if (data.assignated_profiles["CONTROLLER"].length > 0) {
        result["CONTROLLER"] = _.concat(
          _.toArray(result["CONTROLLER"]),
          _.toArray(data.assignated_profiles["CONTROLLER"])
        );
      }

      this.errors = [];
      const formattedForm = {
        name: data.name,
        surname: data.surname,
        isBoss: data.isBoss | false,
        email: data.email,
        login: data.login,
        stakeholderDepartmentId: data.department.id,
        stakeholderCompanyId: localStorage.getItem("managedCompanyId"),
        environment: {
          notifications: {
            sendemail: data.sendemail ? "YES" : "NO"
          },
          languages: {
            default: data.language
          },
          timezone: data.timezone
        },
        federated: data.federated ? 1 : 0,
        profiles: result,
        phoneNumber: data.phoneNumber
      };

      if (!this.errors.length) {
        createStakeholder("people", formattedForm)
          .then(response => {
            this.$root.$bvToast.toast(
              this.$t("admin.form.success.create_user_body", {
                user: response.data.records.name
              }),
              {
                title: this.$t("admin.form.success.create_user_header"),
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center"
              }
            );
            this.loadUsers();
            this.loadCompanySelected();
            this.loadDepartments();
            this.$router.push({
              name: "UsersList"
            });
            this.isSubmitting = false;
          })
          .catch(error => {
            this.errors.push({
              name: error.response.data.records.userMessage,
              code: error.response.data.records.applicationCode,
              entity: "user"
            });
            this.$root.$bvToast.toast(
              this.callGetError(error.response.data.records.applicationCode),
              {
                title: this.callGetError("create_user"),
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center"
              }
            );
            this.isSubmitting = false;
          });
      }
    }
  },
  computed: {
    ...mapGetters("Admin", ["companySelected"]),
    fields() {
      let fields = [
        {
          id: "name",
          required: true,
          type: "text",
          validation: validText(this.form.name),
          maxLength: 255
        },
        {
          id: "surname",
          required: true,
          type: "text",
          validation: validText(this.form.surname),
          maxLength: 255
        },
        {
          id: "email",
          required: true,
          type: "text",
          validation: validRFC5322(this.form.email),
          change: () => {
            this.form.login = this.form.email;
          }
        },
        {
          id: "login",
          type: "text",
          required: false,
          validation: validRFC5322(this.form.login),
          autocomplete: true
        },
        {
          id: "phoneNumber",
          required: false,
          type: "text",
          validation: validPhone(this.form.phoneNumber)
        },
        {
          id: "department",
          required: true,
          type: "select",
          label: "label",
          options: this.departmentOptions
        },
        {
          id: "timezone",
          type: "select",
          required: true,
          options: this.timezones
        },
        {
          id: "language",
          type: "select",
          required: true,
          options: _.map(
            this.companySelected.environment.languages.list,
            language => language
          )
        },
        {
          id: "profile",
          type: "multiselect",
          required: true,
          options: this.profiles
        },
        {
          id: "isBoss",
          required: false,
          type: "boolean",
          width: 50,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        },
        {
          id: "sendemail",
          type: "boolean",
          required: true,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        }
      ];
      if (this.companySelected.servicesConfiguration.saml?.idp) {
        fields.push({
          id: "federated",
          type: "boolean",
          required: true,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        });
      }

      if (this.companiesProfiles.length > 0) {
        fields.push({
          id: "assignated_profiles",
          type: "profiles_table",
          required: false,
          options: this.companiesProfiles,
          fields: this.tableFields,
          size: 12,
          currentPage: 1
        });
      }

      return fields;
    },
    departmentOptions() {
      var departments = [];
      _.each(this.departments, department => {
        departments.push({
          id: department.stakeholderId,
          label: department.name
        });
      });
      return departments;
    }
  },
  mounted() {
    if (!this.isCompanySelectedLoaded) {
      this.loadCompanySelected();
    }
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    axios
      .all([
        getDepartments(stakeholderCompanyId),
        getConstants("PROFILE_USER,PROFILE_CONTROLLER,PROFILE_ADMIN"),
        getCompanies(stakeholderCompanyId),
        getTimezones()
      ])
      .then(
        axios.spread(
          (
            departmentsResponse,
            profilesResponse,
            companiesResponse,
            timezonesResponse
          ) => {
            this.timezones = timezonesResponse.data.records;
            this.departments = departmentsResponse.data.records;
            this.profiles = _.map(profilesResponse.data.records, profile =>
              parseProfile(profile)
            );
            let managedCompanies = companiesResponse.data.records;
            _.each(managedCompanies, element => {
              this.companiesProfiles.push({
                id: element.stakeholderId,
                name: element.name,
                controller: false,
                admin: false,
                user: element.stakeholderId == stakeholderCompanyId
              });
            });
            this.form = {
              name: "",
              surname: "",
              email: "",
              login: "",
              department: "",
              phoneNumber: "",
              timezone: this.companySelected.environment.timezone,
              language: this.companySelected.environment.languages.default,
              isBoss: false,
              profile: [],
              assignated_profiles: {
                CONTROLLER: [],
                ADMIN: []
              },
              sendemail:
                this.companySelected.environment.notifications.sendemail ==
                "YES"
            };

            this.form.profile.push(parseProfile("USER"));

            if (this.companySelected.servicesConfiguration.saml?.idp) {
              this.form.federated = true;
            }

            this.loaded = true;
          }
        )
      );
  }
};
</script>
